


form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: center;
  align-items: center;

}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;

}

/* MEDIA Query (Medium devices) */
@media screen and (max-width: 1024px) {
    .container.contact_container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* MEDIA Query (Small devices) */
@media screen and (max-width: 600px) {
    .container.contact_container {
       width: var(--container-width-sm);
}
}