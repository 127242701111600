
:root {
  --color-bg: #a09abc; 
  --color-bg-variant: #b6a6ca;
  --color-primary: #a098af;
  --color-primary-variant: #a792e2;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-text: rgb(62, 4, 83);

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
}
.headerStyle {
    background: purple;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 0.5;
    height: 6rem;
   
   
    
}

  h2 {
    font-size: 30px;
    text-align: center;
}

p {
    font-size: medium;
}

img {
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20rem;
  height: auto
}

.bodyStyle {
    display: flex;
    flex-direction: column;
    line-height: 0;

    

}

.bodyStyle p {
  font-size: 1vw;
  
  text-align: center;
}


.container {
  padding-left: 20%;
  padding-right: 20%;
  color: var(--color-text);
  border-color: red;
  position: relative;
}

.btn {
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  color: var(--color-text);
  background: #8a76bd;
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.footer {
  background: purple;
  height: 1.5rem;
  width: 100%
}

a:link {
  color: white;
}

a:visited {
  color: rgb(233, 187, 229);
}
a:hover {
 font-weight: bold;
}